<template>
  <div>
    <!-- BG -->
    <Parallax />

    <FinancialConsulting />
    <CheckTaxService />
    <CashDesign />

    <!-- 문의 -->
    <ContactUs />

    <!-- 푸터 -->
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    Parallax: () => import('@/components/home/Parallax'),
    FinancialConsulting: () => import('@/components/home/FinancialConsulting'),
    CheckTaxService: () => import('@/components/home/CheckTaxService'),
    CashDesign: () => import('@/components/home/CashDesign'),
    ContactUs: () => import('@/components/home/ContactUs'),
    Footer: () => import('@/components/layout/Footer')
  }
}
</script>
